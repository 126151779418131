/* JS - (c) mpunkt GmbH */
// Include jQuery
const $ = require('jquery');

// jQuery Definitionen
window.jQuery = $;
window.$ = $;

require('../vendor/modernizr.touch');
require('../vendor/jquery-zoombox.min');
require('../vendor/slick.min.js');
require('./functions');

(function($) {
  /**
   * Ajax-Formulare
   * @param conf
   */
  $.fn.mpAjaxForm = function(conf) {
    $(this).find('form:first').submit(function() {
      const form = $(this);
      const ajaxForm = $(form).find('input[name=ajaxform]').val();

      // Formular per Ajax senden?
      if (ajaxForm > 0 && $(form).attr('enctype') !== 'multipart/form-data') {
        $(form).find('input[name=ajaxform]').attr('name', 'ajax_form');
        const form_data = $(this).find('input:not([type=checkbox],[type=radio],[type=submit],[type=image],[type=reset]), input[type=checkbox]:checked, input[type=radio]:checked, textarea, select').serialize();

        $.ajax({
          url: form.attr('action'),
          type: form.attr('method'),
          data: form_data,
          success: function(html) {
            const cAjaxForm = $('#c' + ajaxForm);

            cAjaxForm.replaceWith(html);
            cAjaxForm.mpAjaxForm(conf);

            const formTop = cAjaxForm.offset().top;
            if ($(window).scrollTop() > formTop) {
              $('html,body').animate({scrollTop: formTop}, 500);
            }
          },
        });
        return false;
      }
    });
  };
})($);

$(window).on('load', function() {
  if (location.hash !== '' && $(location.hash).length > 0) {
    $('html,body').animate({scrollTop: $(location.hash).offset().top}, 500);
  }
});

$(window).bind('hashchange', function(e) {
  e.preventDefault();
  if (location.hash !== '' && $(location.hash).length > 0) {
    $('html,body').animate({scrollTop: $(location.hash).offset().top}, 500);
  }
});

// Document Ready
$(function() {
  // CSS-Klasse wechseln, wenn JS aktiviert ist (für Fallbacks)
  $('html:first').removeClass('no-js').addClass('js');

  // Zoombox
  $('.zoombox').zoombox();

  // Bypass-Menu Button
  $('.csc-menu-bypass').click(function(e) {
    e.preventDefault();
    const wrap = $(this).closest('.csc-frame');
    const scrollheight = wrap.offset().top + wrap.height();
    $('html,body').animate({scrollTop: scrollheight}, 500);
  });

  $('.csc-mailform').mpAjaxForm();

  // Responsive Menu
  $('#menu-button').click(function() {
    $('body').toggleClass('menu-open');
  });
  $('.menu-close, #responsive-overlay').click(function() {
    $('body').removeClass('menu-open');
  });

  // Slideshow-Einstellungen
  $('.slick-slider').slick({
    prevArrow: '<button type="button" class="slick-prev slick-arrow" aria-label="Previous" role="button"><span><i>Zurück</i><i>←</i></span></button>',
    nextArrow: '<button type="button" class="slick-next slick-arrow" aria-label="Next" role="button"><span><i>Weiter</i><i>→</i></span></button>',
  });

});