/**
 * Hier bitte deine Functions einbauen und nicht mehr in die app.js
 */

(function ($) {
    $.extend({
        /**
         * Scripts asynchron laden
         * @param source
         * @param callback
         * @param cache
         */
        loadScript: function (source, callback, cache) {
            const c = (typeof cache == 'undefined' ? true : cache);
            (function (d, t) {
                const g = d.createElement(t),
                    s = d.getElementsByTagName(t)[0];
                g.src = source + (c === false ? '?_=' + new Date().getTime() : '');
                g.onload = function () {
                    callback();
                };
                s.parentNode.insertBefore(g, s);
            }(document, 'script'));
        }
    });
})($);